import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Row } from "../components/styled"
import styled from "styled-components"
import { Button } from "../components/button"

const NotFoundSection = styled(Section)`
  min-height: 100vh;
`

const NotFoundRow = styled(Row)`
  min-height: 300px;
  height: 100%;
  display: grid;
  gap: 40px;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  p {
    font-size: 20px;
  }
`

const navItems = [
  {
    title: "Danse",
    path: "/danse",
  },
  {
    title: "Fitness",
    path: "/fitness",
  },
]

const NotFoundPage = () => (
  <Layout navItems={navItems}>
    <SEO title="Page inexistante" />
    <NotFoundSection hero>
      <NotFoundRow>
        <p>Oups, cette page n'existe pas</p>
        <Button to="/" color="pink">
          Revenir à l'acceuil
        </Button>
      </NotFoundRow>
    </NotFoundSection>
  </Layout>
)

export default NotFoundPage
